import React from 'react';
import { Users, UserPlus, MessageCircle, Bell } from 'lucide-react';

interface Friend {
  id: string;
  name: string;
  avatar: string;
  mutualFriends: number;
  status: 'online' | 'offline';
  lastActive?: string;
}

const suggestedFriends: Friend[] = [
  {
    id: '1',
    name: 'Sarah Chen',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
    mutualFriends: 12,
    status: 'online',
  },
  {
    id: '2',
    name: 'Mike Ross',
    avatar: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e',
    mutualFriends: 8,
    status: 'offline',
    lastActive: '2h ago',
  },
];

export default function Connect() {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex items-center space-x-3">
            <div className="p-3 bg-purple-100 rounded-lg">
              <Users className="h-6 w-6 text-purple-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Friends</p>
              <h3 className="text-xl font-bold">1,234</h3>
            </div>
          </div>
        </div>
        
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex items-center space-x-3">
            <div className="p-3 bg-blue-100 rounded-lg">
              <UserPlus className="h-6 w-6 text-blue-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Pending</p>
              <h3 className="text-xl font-bold">23</h3>
            </div>
          </div>
        </div>
        
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex items-center space-x-3">
            <div className="p-3 bg-green-100 rounded-lg">
              <MessageCircle className="h-6 w-6 text-green-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Messages</p>
              <h3 className="text-xl font-bold">89</h3>
            </div>
          </div>
        </div>
        
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex items-center space-x-3">
            <div className="p-3 bg-yellow-100 rounded-lg">
              <Bell className="h-6 w-6 text-yellow-600" />
            </div>
            <div>
              <p className="text-sm text-gray-500">Notifications</p>
              <h3 className="text-xl font-bold">45</h3>
            </div>
          </div>
        </div>
      </div>

      {/* Friend Suggestions */}
      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-6">People You May Know</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {suggestedFriends.map((friend) => (
            <div key={friend.id} className="bg-white p-6 rounded-xl shadow-sm">
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <img
                    src={friend.avatar}
                    alt={friend.name}
                    className="w-16 h-16 rounded-full object-cover"
                  />
                  <span
                    className={`absolute bottom-0 right-0 w-4 h-4 border-2 border-white rounded-full ${
                      friend.status === 'online' ? 'bg-green-500' : 'bg-gray-400'
                    }`}
                  />
                </div>
                <div className="flex-1">
                  <h3 className="font-semibold">{friend.name}</h3>
                  <p className="text-sm text-gray-500">
                    {friend.mutualFriends} mutual friends
                  </p>
                  {friend.status === 'offline' && friend.lastActive && (
                    <p className="text-xs text-gray-400">
                      Last active {friend.lastActive}
                    </p>
                  )}
                </div>
                <button className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600">
                  Connect
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Communities */}
      <div>
        <h2 className="text-2xl font-bold mb-6">Popular Communities</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[1, 2, 3].map((community) => (
            <div key={community} className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="h-32 bg-gradient-to-r from-purple-500 to-blue-500" />
              <div className="p-6">
                <h3 className="font-semibold mb-2">African Tech Hub {community}</h3>
                <p className="text-sm text-gray-600 mb-4">
                  A community of tech enthusiasts across Africa
                </p>
                <div className="flex items-center justify-between">
                  <span className="text-sm text-gray-500">1.2K members</span>
                  <button className="px-4 py-2 bg-gray-100 text-gray-700 rounded-lg hover:bg-gray-200">
                    Join
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
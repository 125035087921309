import React from 'react';
import { Upload, Edit3, Settings, TrendingUp } from 'lucide-react';

export default function Studio() {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold mb-2">Tam Tam Studio</h1>
        <p className="text-gray-600">Create, edit, and manage your content</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Upload Section */}
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex items-center justify-center w-16 h-16 bg-purple-100 rounded-lg mb-4">
            <Upload className="h-8 w-8 text-purple-600" />
          </div>
          <h2 className="text-xl font-semibold mb-2">Upload Content</h2>
          <p className="text-gray-600 mb-4">Share your videos with the world</p>
          <button className="w-full px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700">
            Upload Now
          </button>
        </div>

        {/* Edit Section */}
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex items-center justify-center w-16 h-16 bg-blue-100 rounded-lg mb-4">
            <Edit3 className="h-8 w-8 text-blue-600" />
          </div>
          <h2 className="text-xl font-semibold mb-2">Edit Videos</h2>
          <p className="text-gray-600 mb-4">Enhance your content with our tools</p>
          <button className="w-full px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">
            Start Editing
          </button>
        </div>

        {/* Analytics Section */}
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex items-center justify-center w-16 h-16 bg-green-100 rounded-lg mb-4">
            <TrendingUp className="h-8 w-8 text-green-600" />
          </div>
          <h2 className="text-xl font-semibold mb-2">Analytics</h2>
          <p className="text-gray-600 mb-4">Track your content performance</p>
          <button className="w-full px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700">
            View Stats
          </button>
        </div>

        {/* Settings Section */}
        <div className="bg-white p-6 rounded-xl shadow-sm">
          <div className="flex items-center justify-center w-16 h-16 bg-gray-100 rounded-lg mb-4">
            <Settings className="h-8 w-8 text-gray-600" />
          </div>
          <h2 className="text-xl font-semibold mb-2">Settings</h2>
          <p className="text-gray-600 mb-4">Manage your studio preferences</p>
          <button className="w-full px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700">
            Configure
          </button>
        </div>
      </div>

      {/* Recent Videos */}
      <div className="mt-12">
        <h2 className="text-2xl font-bold mb-6">Recent Videos</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {[1, 2, 3].map((video) => (
            <div key={video} className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="aspect-video bg-gray-100"></div>
              <div className="p-4">
                <h3 className="font-semibold mb-1">Video Title {video}</h3>
                <p className="text-sm text-gray-600">Published 2 days ago</p>
                <div className="flex items-center justify-between mt-4">
                  <span className="text-sm text-gray-600">1.2K views</span>
                  <button className="text-purple-600 hover:text-purple-700">Edit</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
import React, { useState } from 'react';
import { MapPin, Hotel, Car, DollarSign, Utensils, Camera } from 'lucide-react';

interface Country {
  code: string;
  name: string;
  services: Service[];
}

interface Service {
  id: string;
  type: 'hotels' | 'transport' | 'exchange' | 'food' | 'tourism';
  name: string;
  rating: number;
  description: string;
  image: string;
}

const countries: Country[] = [
  {
    code: 'EG',
    name: 'Egypt',
    services: [
      {
        id: 'eg1',
        type: 'hotels',
        name: 'Nile Ritz-Carlton',
        rating: 4.8,
        description: 'Luxury hotel with pyramid views',
        image: 'https://images.unsplash.com/photo-1549638441-b787d2e11f14'
      },
      {
        id: 'eg2',
        type: 'tourism',
        name: 'Pyramids Tour',
        rating: 4.9,
        description: 'Guided tours of the pyramids',
        image: 'https://images.unsplash.com/photo-1523805009345-7448845a9e53'
      }
    ]
  },
  {
    code: 'KE',
    name: 'Kenya',
    services: [
      {
        id: 'ke1',
        type: 'hotels',
        name: 'Giraffe Manor',
        rating: 4.9,
        description: 'Unique wildlife experience',
        image: 'https://images.unsplash.com/photo-1523805009345-7448845a9e53'
      },
      {
        id: 'ke2',
        type: 'tourism',
        name: 'Masai Mara Safari',
        rating: 4.9,
        description: 'Experience the great migration',
        image: 'https://images.unsplash.com/photo-1523805009345-7448845a9e53'
      }
    ]
  },
  {
    code: 'ZA',
    name: 'South Africa',
    services: [
      {
        id: 'za1',
        type: 'hotels',
        name: 'Table Bay Hotel',
        rating: 4.7,
        description: 'Luxury waterfront accommodation',
        image: 'https://images.unsplash.com/photo-1445019980597-93fa8acb246c'
      },
      {
        id: 'za2',
        type: 'tourism',
        name: 'Cape Winelands Tour',
        rating: 4.8,
        description: 'Wine tasting experience',
        image: 'https://images.unsplash.com/photo-1516594798947-e65505dbb29d'
      }
    ]
  },
  {
    code: 'CI',
    name: 'Côte d\'Ivoire',
    services: [
      {
        id: 'ci1',
        type: 'hotels',
        name: 'Hotel Ivoire Sofitel',
        rating: 4.6,
        description: 'Luxury hotel in Abidjan',
        image: 'https://images.unsplash.com/photo-1566073771259-6a8506099945'
      },
      {
        id: 'ci2',
        type: 'hotels',
        name: 'Tiama Hotel',
        rating: 4.5,
        description: 'Business hotel in city center',
        image: 'https://images.unsplash.com/photo-1551882547-ff40c63fe5fa'
      }
    ]
  },
  {
    code: 'GH',
    name: 'Ghana',
    services: [
      {
        id: 'gh1',
        type: 'hotels',
        name: 'Kempinski Hotel',
        rating: 4.7,
        description: 'Luxury in Accra',
        image: 'https://images.unsplash.com/photo-1542314831-068cd1dbfeeb'
      },
      {
        id: 'gh2',
        type: 'food',
        name: 'Buka Restaurant',
        rating: 4.6,
        description: 'Traditional Ghanaian cuisine',
        image: 'https://images.unsplash.com/photo-1555396273-367ea4eb4db5'
      }
    ]
  },
  {
    code: 'NG',
    name: 'Nigeria',
    services: [
      {
        id: 'ng1',
        type: 'hotels',
        name: 'Transcorp Hilton',
        rating: 4.7,
        description: 'Luxury in Abuja',
        image: 'https://images.unsplash.com/photo-1566073771259-6a8506099945'
      },
      {
        id: 'ng2',
        type: 'food',
        name: 'Yellow Chilli',
        rating: 4.8,
        description: 'Modern Nigerian cuisine',
        image: 'https://images.unsplash.com/photo-1555396273-367ea4eb4db5'
      }
    ]
  }
];

const serviceIcons = {
  hotels: Hotel,
  transport: Car,
  exchange: DollarSign,
  food: Utensils,
  tourism: Camera
};

export default function ServicesDropdown() {
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<string | null>(null);

  return (
    <div>
      <div className="bg-gray-50 p-4 rounded-lg mb-4">
        <div className="relative">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="w-full flex items-center justify-between px-4 py-2 bg-white rounded-lg shadow-sm hover:bg-gray-50 border border-gray-200"
          >
            <div className="flex items-center space-x-2">
              <MapPin className="h-5 w-5 text-purple-500" />
              <span>{selectedCountry?.name || 'Select Country'}</span>
            </div>
            <span className={`transition-transform ${isOpen ? 'rotate-180' : ''}`}>▼</span>
          </button>

          {isOpen && (
            <div className="absolute top-full left-0 right-0 mt-2 bg-white rounded-lg shadow-lg z-50 border border-gray-200">
              <div className="p-2 max-h-36 overflow-y-auto">
                {countries.map((country) => (
                  <button
                    key={country.code}
                    onClick={() => {
                      setSelectedCountry(country);
                      setIsOpen(false);
                      setSelectedService(null);
                    }}
                    className="w-full text-left px-4 py-2 rounded-lg hover:bg-gray-100"
                  >
                    {country.name}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
        {Object.entries(serviceIcons).map(([type, Icon]) => (
          <button
            key={type}
            onClick={() => setSelectedService(type)}
            className={`flex flex-col items-center justify-center p-4 rounded-lg border ${
              !selectedCountry 
                ? 'opacity-50 cursor-not-allowed border-gray-200' 
                : selectedService === type
                ? 'border-purple-500 bg-purple-50'
                : 'border-gray-200 hover:border-purple-500'
            }`}
            disabled={!selectedCountry}
          >
            <Icon className="h-6 w-6 text-purple-500 mb-2" />
            <span className="text-sm capitalize">{type}</span>
          </button>
        ))}
      </div>

      {selectedCountry && selectedService && (
        <div className="mt-4 space-y-4">
          {selectedCountry.services
            .filter(service => service.type === selectedService)
            .map(service => (
              <div key={service.id} className="bg-white p-4 rounded-lg shadow-sm">
                <div className="flex items-center justify-between">
                  <h3 className="font-semibold">{service.name}</h3>
                  <div className="flex items-center">
                    <span className="text-yellow-400">★</span>
                    <span className="ml-1">{service.rating}</span>
                  </div>
                </div>
                <p className="text-sm text-gray-600 mt-1">{service.description}</p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
import React from 'react';
import { Radio, Users, Calendar, Play } from 'lucide-react';

export default function Live() {
  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold mb-2">Tam Tam Live</h1>
        <p className="text-gray-600">Connect with your audience in real-time</p>
      </div>

      {/* Quick Actions */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
        <button className="flex items-center justify-center space-x-3 p-6 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-xl hover:from-purple-600 hover:to-blue-600">
          <Radio className="h-6 w-6" />
          <span className="font-medium">Go Live Now</span>
        </button>
        <button className="flex items-center justify-center space-x-3 p-6 bg-white text-gray-800 rounded-xl border-2 border-gray-200 hover:bg-gray-50">
          <Calendar className="h-6 w-6" />
          <span className="font-medium">Schedule Stream</span>
        </button>
        <button className="flex items-center justify-center space-x-3 p-6 bg-white text-gray-800 rounded-xl border-2 border-gray-200 hover:bg-gray-50">
          <Play className="h-6 w-6" />
          <span className="font-medium">Practice Mode</span>
        </button>
      </div>

      {/* Live Now Section */}
      <div className="mb-12">
        <h2 className="text-2xl font-bold mb-6">Live Now</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {[1, 2, 3].map((stream) => (
            <div key={stream} className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="relative">
                <div className="aspect-video bg-gray-100"></div>
                <div className="absolute top-2 left-2">
                  <span className="px-2 py-1 bg-red-500 text-white text-xs font-medium rounded-full">
                    LIVE
                  </span>
                </div>
                <div className="absolute bottom-2 left-2">
                  <span className="px-2 py-1 bg-black/50 text-white text-xs rounded-full">
                    <Users className="h-3 w-3 inline mr-1" />
                    1.2K watching
                  </span>
                </div>
              </div>
              <div className="p-4">
                <h3 className="font-semibold mb-1">Live Stream Title {stream}</h3>
                <p className="text-sm text-gray-600">Streamer Name</p>
                <div className="mt-4 flex items-center space-x-2">
                  <span className="px-2 py-1 bg-gray-100 text-xs rounded-full">Music</span>
                  <span className="px-2 py-1 bg-gray-100 text-xs rounded-full">Talk Show</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Upcoming Streams */}
      <div>
        <h2 className="text-2xl font-bold mb-6">Upcoming Streams</h2>
        <div className="space-y-4">
          {[1, 2, 3].map((event) => (
            <div key={event} className="bg-white p-4 rounded-xl shadow-sm flex items-center space-x-4">
              <div className="flex-shrink-0 w-16 h-16 bg-gray-100 rounded-lg flex items-center justify-center">
                <Calendar className="h-8 w-8 text-gray-400" />
              </div>
              <div className="flex-1">
                <h3 className="font-semibold">Upcoming Stream {event}</h3>
                <p className="text-sm text-gray-600">Tomorrow at 3:00 PM</p>
              </div>
              <button className="px-4 py-2 bg-purple-100 text-purple-600 rounded-lg hover:bg-purple-200">
                Set Reminder
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
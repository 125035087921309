import React from 'react';
import Layout from '../components/Layout';
import Feed from './Feed';

export default function Home() {
  return (
    <Layout>
      <Feed />
    </Layout>
  );
}
import React, { useState } from 'react';
import { Star, TrendingUp, Radio, Award, MapPin } from 'lucide-react';

interface Leader {
  id: string;
  name: string;
  country: string;
  avatar: string;
  title: string;
  rating: number;
  views: string;
  panAfricanismQuote: string;
  achievements: string[];
  isLive?: boolean;
}

const leaders: Leader[] = [
  {
    id: '1',
    name: 'William Ruto',
    country: 'Kenya',
    avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e',
    title: 'President of Kenya',
    rating: 4.5,
    views: '12.5K',
    panAfricanismQuote: "Unity is Africa's strength. Together we build a prosperous continent.",
    achievements: [
      'Digital transformation initiative',
      'Green energy projects',
      'Educational reforms'
    ],
    isLive: true
  },
  {
    id: '2',
    name: 'Cyril Ramaphosa',
    country: 'South Africa',
    avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d',
    title: 'President of South Africa',
    rating: 4.2,
    views: '15.3K',
    panAfricanismQuote: "Africa's time is now. We must unite for progress.",
    achievements: [
      'Economic recovery program',
      'Infrastructure development',
      'Social justice reforms'
    ]
  }
];

export default function LeaderRankings() {
  const [selectedLeader, setSelectedLeader] = useState<Leader | null>(null);

  return (
    <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
      <div className="flex items-center space-x-2 mb-6">
        <TrendingUp className="h-6 w-6 text-purple-600" />
        <h2 className="text-xl font-bold">Top African Leaders This Week</h2>
      </div>

      <div className="divide-y divide-gray-200">
        {leaders.map((leader, index) => (
          <div key={leader.id} className="py-4 first:pt-0 last:pb-0 hover:bg-gray-50 rounded-lg">
            <div className="flex items-center space-x-4">
              <div className="text-2xl font-bold text-purple-600 w-8">
                #{index + 1}
              </div>
              <img
                src={leader.avatar}
                alt={leader.name}
                className="w-12 h-12 rounded-full object-cover"
              />
              <div className="flex-1">
                <div className="flex items-center space-x-2">
                  <h3 className="font-semibold">{leader.name}</h3>
                  {leader.isLive && (
                    <span className="px-2 py-0.5 bg-red-500 text-white text-xs rounded-full">
                      LIVE
                    </span>
                  )}
                </div>
                <p className="text-sm text-gray-600">{leader.title}</p>
                <div className="flex items-center space-x-2 mt-1">
                  <Star className="h-4 w-4 text-yellow-400 fill-current" />
                  <span className="text-sm font-medium">{leader.rating}</span>
                  <span className="text-sm text-gray-500">({leader.views} views)</span>
                </div>
              </div>
              <button
                onClick={() => setSelectedLeader(leader)}
                className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 text-sm"
              >
                View Profile
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Leader Profile Modal */}
      {selectedLeader && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center space-x-4">
                  <img
                    src={selectedLeader.avatar}
                    alt={selectedLeader.name}
                    className="w-20 h-20 rounded-full object-cover"
                  />
                  <div>
                    <h2 className="text-2xl font-bold">{selectedLeader.name}</h2>
                    <p className="text-gray-600">{selectedLeader.title}</p>
                  </div>
                </div>
                <button
                  onClick={() => setSelectedLeader(null)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>

              <div className="space-y-6">
                <div>
                  <h3 className="font-semibold mb-2">Pan-Africanism Vision</h3>
                  <blockquote className="border-l-4 border-purple-500 pl-4 italic">
                    "{selectedLeader.panAfricanismQuote}"
                  </blockquote>
                </div>

                <div>
                  <h3 className="font-semibold mb-2">Key Achievements</h3>
                  <ul className="space-y-2">
                    {selectedLeader.achievements.map((achievement, index) => (
                      <li key={index} className="flex items-center space-x-2">
                        <Award className="h-5 w-5 text-purple-500" />
                        <span>{achievement}</span>
                      </li>
                    ))}
                  </ul>
                </div>

                {selectedLeader.isLive && (
                  <div className="mt-6">
                    <button className="w-full flex items-center justify-center space-x-2 px-6 py-3 bg-red-500 text-white rounded-lg hover:bg-red-600">
                      <Radio className="h-5 w-5" />
                      <span>Join Live Stream</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
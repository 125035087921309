import React, { useState } from 'react';
import { Image, Video, Smile } from 'lucide-react';
import PostCard from '../components/PostCard';
import ServicesDropdown from '../components/ServicesDropdown';
import TrendingIssues from '../components/TrendingIssues';
import LeaderRankings from '../components/LeaderRankings';
import { Post } from '../types';

const samplePosts: Post[] = [
  {
    id: '1',
    user: {
      name: 'Sarah Chen',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
      location: 'Nairobi, Kenya',
    },
    content: {
      text: 'Exploring the vibrant markets of Nairobi! The colors, the energy, the people - everything here tells a story. 🌍✨ #AfricanCulture #TamTamMoments',
      media: {
        type: 'image',
        url: 'https://images.unsplash.com/photo-1580933073521-dc49ac0d4e6a',
      },
    },
    timestamp: new Date(),
    likes: 1234,
    comments: 89,
    shares: 23,
  },
  {
    id: '2',
    user: {
      name: 'Mike Ross',
      avatar: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e',
      location: 'Lagos, Nigeria',
    },
    content: {
      text: 'Amazing sunset at Victoria Island! Lagos never fails to amaze me with its beautiful horizons. 🌅 #LagosLife #AfricanSunsets',
      media: {
        type: 'image',
        url: 'https://images.unsplash.com/photo-1516026672322-bc52d61a55d5',
      },
    },
    timestamp: new Date(),
    likes: 2567,
    comments: 145,
    shares: 67,
  },
];

export default function Feed() {
  const [postText, setPostText] = useState('');

  return (
    <div className="max-w-7xl mx-auto px-4 py-6">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Main Feed */}
        <div className="lg:col-span-2">
          {/* Create Post */}
          <div className="bg-white rounded-xl shadow-sm p-4 mb-6">
            <div className="flex items-center space-x-4 mb-4">
              <img
                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330"
                alt="Your avatar"
                className="w-12 h-12 rounded-full object-cover border-2 border-purple-400"
              />
              <input
                type="text"
                value={postText}
                onChange={(e) => setPostText(e.target.value)}
                placeholder="Share your African story..."
                className="flex-1 rounded-full bg-gray-100 px-6 py-3 text-gray-700 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-400"
              />
            </div>
            <div className="flex items-center justify-between px-4">
              <div className="flex space-x-4">
                <button className="flex items-center space-x-2 text-gray-600 hover:text-purple-500">
                  <Image className="h-5 w-5" />
                  <span className="text-sm">Photo</span>
                </button>
                <button className="flex items-center space-x-2 text-gray-600 hover:text-purple-500">
                  <Video className="h-5 w-5" />
                  <span className="text-sm">Video</span>
                </button>
                <button className="flex items-center space-x-2 text-gray-600 hover:text-purple-500">
                  <Smile className="h-5 w-5" />
                  <span className="text-sm">Feeling</span>
                </button>
              </div>
              <button className="px-6 py-2 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-full font-medium hover:from-purple-600 hover:to-blue-600 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">
                Post
              </button>
            </div>
          </div>

          {/* Services Section */}
          <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
            <h2 className="text-xl font-bold mb-4">Services</h2>
            <ServicesDropdown />
          </div>

          {/* Trending Issues */}
          <TrendingIssues />

          {/* Leader Rankings */}
          <LeaderRankings />

          {/* Posts Feed */}
          {samplePosts.map((post) => (
            <PostCard key={post.id} post={post} />
          ))}
        </div>

        {/* Sidebar */}
        <div className="space-y-6">
          {/* Additional widgets can go here */}
        </div>
      </div>
    </div>
  );
}
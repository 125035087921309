import React, { useState } from 'react';
import { ChevronUp, ChevronDown, Heart, MessageCircle, Share2, Music2 } from 'lucide-react';

interface ShortVideo {
  id: string;
  user: {
    name: string;
    username: string;
    avatar: string;
  };
  description: string;
  music: string;
  likes: number;
  comments: number;
  shares: number;
  videoUrl: string;
}

const sampleVideos: ShortVideo[] = [
  {
    id: '1',
    user: {
      name: 'Sarah Dance',
      username: '@sarahdance',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330',
    },
    description: '✨ Dancing to the rhythm of Africa! #AfricanDance #TamTamExpress',
    music: 'African Beats - Original Sound',
    likes: 45200,
    comments: 1200,
    shares: 890,
    videoUrl: 'https://example.com/video1.mp4',
  },
  // Add more sample videos as needed
];

export default function Express() {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleNext = () => {
    setCurrentVideoIndex((prev) => 
      prev < sampleVideos.length - 1 ? prev + 1 : prev
    );
  };

  const handlePrevious = () => {
    setCurrentVideoIndex((prev) => prev > 0 ? prev - 1 : prev);
  };

  const currentVideo = sampleVideos[currentVideoIndex];

  return (
    <div className="h-[calc(100vh-8rem)] bg-black">
      <div className="relative h-full">
        {/* Video Player */}
        <div className="absolute inset-0">
          <video
            className="w-full h-full object-cover"
            src={currentVideo.videoUrl}
            loop
            autoPlay
            muted
            playsInline
          />
        </div>

        {/* Navigation Buttons */}
        <button
          onClick={handlePrevious}
          className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white"
        >
          <ChevronUp className="h-8 w-8" />
        </button>
        <button
          onClick={handleNext}
          className="absolute bottom-20 left-4 transform text-white"
        >
          <ChevronDown className="h-8 w-8" />
        </button>

        {/* Video Info */}
        <div className="absolute bottom-20 left-4 right-16 text-white p-4">
          <div className="flex items-center space-x-3 mb-4">
            <img
              src={currentVideo.user.avatar}
              alt={currentVideo.user.name}
              className="w-12 h-12 rounded-full border-2 border-white"
            />
            <div>
              <h3 className="font-bold">{currentVideo.user.name}</h3>
              <p className="text-sm opacity-80">{currentVideo.user.username}</p>
            </div>
            <button className="ml-auto px-4 py-1.5 bg-purple-500 rounded-full text-sm font-medium">
              Follow
            </button>
          </div>
          <p className="mb-2">{currentVideo.description}</p>
          <div className="flex items-center space-x-2">
            <Music2 className="h-4 w-4" />
            <span className="text-sm">{currentVideo.music}</span>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="absolute right-4 bottom-32 flex flex-col space-y-6">
          <button className="flex flex-col items-center text-white">
            <div className="w-12 h-12 flex items-center justify-center bg-gray-800/50 rounded-full">
              <Heart className="h-6 w-6" />
            </div>
            <span className="text-xs mt-1">{currentVideo.likes}</span>
          </button>
          <button className="flex flex-col items-center text-white">
            <div className="w-12 h-12 flex items-center justify-center bg-gray-800/50 rounded-full">
              <MessageCircle className="h-6 w-6" />
            </div>
            <span className="text-xs mt-1">{currentVideo.comments}</span>
          </button>
          <button className="flex flex-col items-center text-white">
            <div className="w-12 h-12 flex items-center justify-center bg-gray-800/50 rounded-full">
              <Share2 className="h-6 w-6" />
            </div>
            <span className="text-xs mt-1">{currentVideo.shares}</span>
          </button>
        </div>
      </div>
    </div>
  );
}
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Login from './pages/Login';
import Home from './pages/Home';
import Express from './pages/Express';
import Studio from './pages/Studio';
import Live from './pages/Live';
import Connect from './pages/Connect';
import Chat from './pages/Chat';
import Register from './pages/Register';
import PrivateRoute from './components/PrivateRoute';

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={
            <PrivateRoute>
              <Home />
            </PrivateRoute>
          } />
          <Route path="/express" element={
            <PrivateRoute>
              <Express />
            </PrivateRoute>
          } />
          <Route path="/studio" element={
            <PrivateRoute>
              <Studio />
            </PrivateRoute>
          } />
          <Route path="/live" element={
            <PrivateRoute>
              <Live />
            </PrivateRoute>
          } />
          <Route path="/connect" element={
            <PrivateRoute>
              <Connect />
            </PrivateRoute>
          } />
          <Route path="/chat" element={
            <PrivateRoute>
              <Chat />
            </PrivateRoute>
          } />
        </Routes>
      </Router>
    </AuthProvider>
  );
}
import React from 'react';
import { TrendingUp, MessageCircle, Share2 } from 'lucide-react';

interface TrendingIssue {
  id: string;
  title: string;
  description: string;
  category: string;
  engagement: {
    discussions: number;
    shares: number;
  };
}

const trendingIssues: TrendingIssue[] = [
  {
    id: '1',
    title: 'African Continental Free Trade Area Implementation',
    description: 'Progress and challenges in implementing the AfCFTA agreement across the continent.',
    category: 'Economy',
    engagement: {
      discussions: 1234,
      shares: 567
    }
  },
  {
    id: '2',
    title: 'Green Energy Revolution in Africa',
    description: 'African nations leading the charge in renewable energy adoption.',
    category: 'Environment',
    engagement: {
      discussions: 892,
      shares: 345
    }
  }
];

export default function TrendingIssues() {
  return (
    <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
      <div className="flex items-center space-x-2 mb-6">
        <TrendingUp className="h-6 w-6 text-purple-600" />
        <h2 className="text-xl font-bold">Trending in Africa</h2>
      </div>

      <div className="space-y-6">
        {trendingIssues.map((issue) => (
          <div key={issue.id} className="border-b border-gray-100 pb-6 last:border-0 last:pb-0">
            <span className="inline-block px-3 py-1 bg-purple-100 text-purple-600 text-sm rounded-full mb-2">
              {issue.category}
            </span>
            <h3 className="font-semibold mb-2">{issue.title}</h3>
            <p className="text-gray-600 text-sm mb-4">{issue.description}</p>
            
            <div className="flex items-center space-x-4 text-sm text-gray-500">
              <button className="flex items-center space-x-1 hover:text-purple-600">
                <MessageCircle className="h-4 w-4" />
                <span>{issue.engagement.discussions} discussions</span>
              </button>
              <button className="flex items-center space-x-1 hover:text-purple-600">
                <Share2 className="h-4 w-4" />
                <span>{issue.engagement.shares} shares</span>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}